// @flow

import { map } from 'ramda'

import { generateIdFromTitle } from '../../../helpers'
import type { OverviewItem } from '../../../types'

const overview = [
  {
    title: 'A Hidden Tropical Gem',
    message:
      'The quaint tropical theme is what sets the professionally decorated ' +
      'Seahorse Cottage apart.',
  },
  {
    title: 'Entertain your Friends and Family',
    message: 'The spacious gourmet kitchen is great for entertaining.',
  },
  {
    title: 'Room Configuration',
    message: '2 Bedroom (1 K, 2 T) 2 Bath. Sleeper sofa in living room.',
  },
]

export const seahorseOverview: OverviewItem[] = map(
  generateIdFromTitle,
  overview,
)

export default seahorseOverview
